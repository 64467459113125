<template>
    <div class="directives-container">
        <div class="allDirectives">
            <el-tabs v-model="activeName">
                <el-tab-pane label="私有指令" name="my">
                <el-form
                    :inline="true"
                    size="mini"
                    :model="searchDirectivesConditions"
                    class="searchForm"
                    ><el-form-item label="名称">
                    <el-input
                        v-model="searchDirectivesConditions.directiveName"
                        placeholder="请输入指令名称"
                        clearable
                    ></el-input>
                    </el-form-item>
                    <el-form-item label="类型">
                    <el-select
                        v-model="searchDirectivesConditions.directiveType"
                        placeholder="指令类型"
                        clearable
                    >
                        <el-option
                        v-for="d in directiveTypes"
                        :key="d.id"
                        :label="d.type_name"
                        :value="d.id"
                        ></el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item>
                    <el-button
                        type="primary"
                        class="searchBtn"
                        @click="searchDirectives"
                        >查询</el-button
                    >
                    </el-form-item>
                </el-form>
                <el-table
                    :data="directivesDataInfo.result"
                    border
                    style="width: 100%"
                    :height="460"
                    class="allDirectivesTable"
                    @selection-change="
                    (s) => {
                        handleCheckedDChange(s, 1)
                    }
                    "
                    >>
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column
                    prop="instruction_name"
                    label="指令名称"
                    sortable
                    ></el-table-column>
                    <el-table-column prop="type_name" label="类型"></el-table-column>
                    <el-table-column
                    prop="instruction_code"
                    label="指令码"
                    sortable
                    :show-overflow-tooltip="true"
                    ></el-table-column>
                    <el-table-column
                    prop="return_code"
                    label="指令回码"
                    sortable
                    :show-overflow-tooltip="true"
                    ></el-table-column>
                </el-table>
                <Pagination
                    ref="pagination"
                    v-if="directivesDataInfo.total > 10"
                    :handleSizeChange="handleSizeChange"
                    :handleCurrentPageChange="handleCurrentPageChange"
                    :total="directivesDataInfo.total"
                    :page-size="10"
                    :page-sizes="[10, 20, 30, 50, 100]"
                />
                </el-tab-pane>
                <el-tab-pane label="公共指令" name="public">
                <el-form
                    :inline="true"
                    size="mini"
                    :model="commonSearchForm"
                    class="searchForm"
                    ><el-form-item label="名称">
                    <el-input
                        v-model="commonSearchForm.instruction_name"
                        placeholder="请输入指令名称"
                        clearable
                    ></el-input>
                    </el-form-item>
                    <el-form-item label="类型">
                    <el-cascader
                        v-model="commonSearchForm.level"
                        :options="directiveTreeDatas"
                        clearable
                        :props="{
                        label: 'name',
                        children: 'children',
                        value: 'id',
                        checkStrictly: true,
                        }"
                        placeholder="请选择"
                    ></el-cascader>
                    </el-form-item>
                    <el-form-item>
                    <el-button
                        type="primary"
                        class="searchBtn"
                        @click="commonSearchClick"
                        >查询</el-button
                    >
                    </el-form-item>
                </el-form>
                <el-table
                    :data="directiveTableDatas"
                    border
                    style="width: 100%"
                    :height="460"
                    class="allDirectivesTable"
                    @selection-change="
                    (s) => {
                        handleCheckedDChange(s, 1)
                    }
                    "
                    >>
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column
                    prop="instruction_name"
                    label="指令名称"
                    sortable
                    ></el-table-column>
                    <el-table-column prop="send_type" label="类型" sortable></el-table-column>
                    <el-table-column
                    prop="instruction_code"
                    label="指令码"
                    sortable
                    :show-overflow-tooltip="true"
                    ></el-table-column>
                    <el-table-column
                    prop="return_code"
                    label="指令回码"
                    sortable
                    :show-overflow-tooltip="true"
                    ></el-table-column>
                </el-table>
                <Pagination
                    ref="commonPagination"
                    v-if="directiveTableListTotal > 10"
                    :handleSizeChange="handleCommonSizeChange"
                    :handleCurrentPageChange="handleCommonCurrentPageChange"
                    :total="directiveTableListTotal"
                    :page-size="10"
                    :page-sizes="[10, 25, 50, 100, 200]"
                    :pageNum="commonPageInfo.page_num"
                />
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="switches">
        <div>
            <el-button
            type="primary"
            icon="el-icon-d-arrow-right"
            @click="addDirectives"
            />
        </div>
        <div>
            <el-button
            type="primary"
            icon="el-icon-d-arrow-left"
            @click="removeDirectives"
            />
        </div>
        </div>
        <div class="selectedDirectives">
        <div class="batchSet">
            <!-- <span class="batchText">批量设置：</span> -->
            <el-button size="mini" @click="addPrivateClick" style="margin-right: 10px;">添加私有指令</el-button>
            <el-popconfirm title="确定要更新吗？" @confirm="updateData">
              <el-button
                  slot="reference"
                  style="marginRight: 10px"
                  type="danger"
                  size="mini"
                  >更新</el-button
              >
            </el-popconfirm>
            <el-button type="primary" v-if="!isJhb" size="mini" @click="showBatchModal"
            >批量设置</el-button
            >
            <el-button size="mini" @click="upAction">上移</el-button>
            <el-button size="mini" @click="downAction">下移</el-button>
            <!-- <div v-for="b in batchSettings" :key="b.key" class="box">
            <label for="ip">{{ b.label }}</label>
            <el-input
                :placeholder="`请输入${b.label}`"
                :id="b.key"
                class="input"
                v-model="batchSettingObj[b.key]"
                clearable
            >
                <el-button slot="append" size="mini" type="primary" class="setBtn" @click="batchSetFunc(b.key)"
                >应用</el-button
                >
            </el-input>
            </div> -->
            <el-checkbox v-if="!isJhb" class="validateChk" v-model="isMustValid"
            >强校验</el-checkbox
            >
        </div>
        <el-table
            :data="bindedDirectivesData"
            border
            style="width: 100%"
            :max-height="rightTableH"
            @selection-change="
            (s) => {
                handleCheckedDChange(s, 2)
            }
            "
        >
            <el-table-column type="selection" min-width="1"> </el-table-column>
            <el-table-column prop="order" label="顺序" min-width="1">
            <template slot-scope="scope">
                {{ scope.$index + 1 }}
            </template>
            </el-table-column>
            <el-table-column prop="instruction_name" label="指令名称" min-width="3">
              <template slot-scope="scope">
                <el-popover
                  placement="bottom"
                  width="200"
                  trigger="click"
                  popper-class="instruction_name_popover"
                  :content="`指令码：${scope.row.instruction_code}\n编码格式：${unicodeMap[scope.row.unicode] || '-'}\n协议类型：${scope.row.type_name || scope.row.send_type}\n回码：${scope.row.return_code || '-'}`">
                  <el-button type="text" slot="reference">{{ scope.row.instruction_name }}</el-button>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
            v-for="cell in editableCells"
            :sortable="cell.sortable"
            :sort-by="cell['sort-by']"
            :key="cell.key"
            :prop="cell.key"
            :min-width="cell.width"
            :label="cell.label"
            >
            <template slot-scope="scope">
                <el-input-number
                v-if="numberInputKeys.indexOf(cell.key) !== -1"
                :controls="false"
                :min="0"
                :value="scope.row[cell.key]"
                :placeholder="cell.placeholder"
                @input="
                    (v) => {
                    onTableCellChange(v, scope.row, cell.key)
                    }
                "
                ></el-input-number>
                <el-input
                v-else
                :value="scope.row[cell.key]"
                :placeholder="cell.placeholder"
                @input="
                    (v) => {
                    onTableCellChange(v, scope.row, cell.key)
                    }
                "
                ></el-input>
            </template>
            </el-table-column>
            <el-table-column
            v-if="isMustValid"
            prop="mustValid"
            label="返回值校验"
            min-width="2"
            >
            <template slot-scope="scope">
                <el-input
                :value="scope.row.mustValid"
                placeholder="必填，请输入"
                @input="
                    (v) => {
                    onTableCellChange(v, scope.row, 'mustValid')
                    }
                "
                ></el-input>
            </template>
            </el-table-column>
        </el-table>
        <BatchDirectivesModal
            ref="batchModal"
            :isMustValid="isMustValid"
            @confirm="batchModalSetFunc"
        />
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import { validateIp } from '@/utils/utils'
import BatchDirectivesModal from '../BatchDirectivesModal'

import _ from 'lodash'
export default {
  components: { Modal, Pagination, BatchDirectivesModal },
  props: {
    modalType: Number,
    isJhb: Boolean,
    initData: Array
  },
  data() {
    return {
      isShowModal: false,
      searchDirectivesConditions: {
        directiveName: '',
        directiveType: '',
      },
      directiveTypes: [],
      directivesDataInfo: {},
      bindedDirectivesData: [],
      checkedD: [],
      checkedBindedD: [],
      editableCells: [
        // {
        //   label: "顺序",
        //   key: "order",
        //   placeholder: "请输入顺序",
        //   width: "2",
        //   // sortable: true,
        //   // ["sort-by"]: this.sortFunc,
        // },
        {
          label: 'IP',
          key: 'ip',
          placeholder: '请输入IP',
          width: '3',
        },
        {
          label: '端口号',
          key: 'port',
          placeholder: '请输入端口号',
          width: '2',
        },
        {
          label: '延时（毫秒）',
          key: 'delay',
          placeholder: '请输入延时时间',
          width: '2',
        },
        {
          label: '次数',
          key: 'counts',
          placeholder: '请输入次数',
          width: '2',
        },
      ],
      batchSettingObj: {
        ip: '',
        port: '',
      },
      unicodeMap: {
        '1': '字符串',
        '2': '16进制'
      },
      batchSettings: [
        {
          label: 'IP地址',
          key: 'ip',
        },
        {
          label: '端口',
          key: 'port',
        },
      ],
      activeName: 'my',
      commonSearchForm: {
        level: [],
        instruction_name: '',
      },
      commonPageInfo: {
        page_num: 1,
        page_size: 10,
      },
      isMustValid: false,
      numberInputKeys: ['order', 'port', 'delay', 'counts'],
      rightTableH: null
    }
  },
  mounted() {
    this.initDirectivesData()
    this.getDirectiveTypes()
  },
  created() {
    this.initView()
      this.getCommonTreeDatas()
      this.getCommonInstList(true)
      this.$nextTick(() => {
        var rightH = document.getElementsByClassName('directives-container')[0].clientHeight;
        this.rightTableH = rightH - 32 - 16;
      });
  },
  watch: {
    modalType() {
      this.initDirectivesData()
    },
    activeName: function (val, oldVal) {
      if (val !== oldVal) {
        this.checkedD = []
      }
    },
    initData: {
      handler: function(val) {
        this.bindedDirectivesData = JSON.parse(JSON.stringify(val || []));
        this.checkedBindedD = [];
        this.checkedD = [];
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(['curComponent']),
    ...mapGetters([
      'directiveTreeDatas',
      'directiveTableDatas',
      'directiveTableListTotal',
    ]),
  },
  methods: {
    ...mapActions(['getDirectiveTree', 'getDirectiveTableList']),
    addPrivateClick() {
      let routeData = this.$router.resolve({
        path: '/privateResource/manage/add'
      });
      window.open(routeData.href, '_blank');
    },
    getDirectivesDatas() {
      // 检验ip是否都正确
      let isAllRight = true
      for (let i = 0; i < this.bindedDirectivesData.length; i++) {
        let ipVal =
          this.bindedDirectivesData[i].ip &&
          this.bindedDirectivesData[i].ip.trim()
        let isHttp = this.bindedDirectivesData[i].send_type === 'http'
        if (!isHttp && ipVal && !validateIp(ipVal)) {
          isAllRight = false
          break
        }
      }
      if (!isAllRight) {
        this.$message.error('存在不正确的ip地址，请检查并修改')
        return false;
      }
      this.bindedDirectivesData.map((item, index) => {
        this.bindedDirectivesData[index].order = index + 1
      })
      console.log(this.bindedDirectivesData)
      return JSON.parse(JSON.stringify(this.bindedDirectivesData));
    },
    updateData() {
      if (this.bindedDirectivesData.length === 0) {
        this.$message.error('右侧没有要更新的数据')
        return
      }
      const ids = this.bindedDirectivesData.reduce((arr, item) => {
        if (arr.indexOf(item.id) === -1) {
          arr.push(item.id)
        }
        return arr
      }, [])
      this.$request
        .upgradeInstruction({
          ids: ids,
        })
        .then((res) => {
          console.log(res)
          if (!res || res.status != 'success') {
            this.$message.error((res && res.msg) || '更新失败')
            return
          }
          let data = (res && res.data && res.data.result) || []
          if (data && data.length) {
            // 因为右侧勾选的数据会有重复的指令，所以需要比较更新
            let dataMap = {}
            data.map((item) => {
              dataMap[item.id] = item
            })
            this.bindedDirectivesData.map((item, ind) => {
              if (dataMap[item.id]) {
                // 比较更新
                Object.keys(dataMap[item.id]).map((key) => {
                  if (item.hasOwnProperty(key)) {
                    this.bindedDirectivesData[ind][key] = dataMap[item.id][key]
                  }
                })
              }
            })
          }
        })
    },
    upAction() {
      if (!this.checkedBindedD || this.checkedBindedD.length === 0) {
        this.$message.error('请先勾选要上移的数据')
        return
      }
      if (this.checkedBindedD.length > 1) {
        this.$message.error('只能选择一条数据去上移')
        return
      }
      const selectedId = this.checkedBindedD[0].temId
      const currentInd = this.bindedDirectivesData.findIndex(
        (i) => i.temId == selectedId
      )
      if (currentInd === 0) {
        this.$message.error('已经在第一条，不能再上移了')
        return
      }
      this.bindedDirectivesData.splice(currentInd, 1)
      this.bindedDirectivesData.splice(
        currentInd - 1,
        0,
        this.checkedBindedD[0]
      )
    },
    downAction() {
      if (!this.checkedBindedD || this.checkedBindedD.length === 0) {
        this.$message.error('请先勾选要下移的数据')
        return
      }
      if (this.checkedBindedD.length > 1) {
        this.$message.error('只能选择一条数据去下移')
        return
      }
      const selectedId = this.checkedBindedD[0].temId
      const currentInd = this.bindedDirectivesData.findIndex(
        (i) => i.temId == selectedId
      )
      if (currentInd === this.bindedDirectivesData.length - 1) {
        this.$message.error('已经在最后一条，不能再下移了')
        return
      }
      this.bindedDirectivesData.splice(currentInd, 1)
      this.bindedDirectivesData.splice(
        currentInd + 1,
        0,
        this.checkedBindedD[0]
      )
    },
    initView() {
      this.commonSearchForm = {
        level: [],
        instruction_name: '',
      }
      this.commonPageInfo = {
        page_num: 1,
        page_size: 10,
      }
      if (sessionStorage.getItem('modalLevel')) {
        const level = JSON.parse(sessionStorage.getItem('modalLevel'))
        this.commonSearchForm.level = level
      }
      if (sessionStorage.getItem('modalDirectiveType')) {
        const type = sessionStorage.getItem('modalDirectiveType')
        this.searchDirectivesConditions.directiveType = type
      }
    },
    async getCommonTreeDatas() {
      try {
        this.$changeLoadingState(true)
        await this.getDirectiveTree()
      } catch (error) {
        console.log(error)
      }
      this.$changeLoadingState(false)
    },
    commonSearchClick() {
      // 记录搜索条件中的--级别
      sessionStorage.setItem(
        'modalLevel',
        JSON.stringify(this.commonSearchForm.level)
      )
      this.getCommonInstList(true)
    },
    async getCommonInstList(isFirstPage) {
      if (isFirstPage) {
        this.commonPageInfo.page_num = 1
      }
      let queryParams = {
        ...this.commonPageInfo,
        instruction_name: this.commonSearchForm.instruction_name,
        ...Object.assign(
          {},
          this.commonSearchForm.level.length === 2
            ? { company_id: this.commonSearchForm.level[1], public_type_id: '' }
            : {},
          this.commonSearchForm.level.length === 3
            ? { company_id: '', public_type_id: this.commonSearchForm.level[2] }
            : {}
        ),
      }
      try {
        this.$changeLoadingState(true)
        await this.getDirectiveTableList(queryParams)
      } catch (error) {
        console.log(error)
      }
      this.$changeLoadingState(false)
    },
    sortHandler(column, prop, order) {
      if (!column.order) return
      const data = this.bindedDirectivesData.sort((a, b) =>
        column.order == 'descending'
          ? b.order * 1 - a.order * 1
          : a.order * 1 - b.order * 1
      )
      this.bindedDirectivesData = data
    },
    dialogClose() {
      this.isShowModal = false
      this.batchSettingObj = {}
    },
    sortFunc(a, b) {
      return a.order * 1
    },
    showBatchModal() {
      if (!this.checkedBindedD || this.checkedBindedD.length === 0) {
        this.$message.error('请先勾选要批量设置的数据')
        return
      }
      console.log(this.checkedBindedD)
      this.$refs.batchModal.show()
    },
    batchModalSetFunc(params) {
      this.checkedBindedD.map((item) => {
        const ind = this.bindedDirectivesData.findIndex(
          (it) => it.temId === item.temId
        )
        Object.keys(params).forEach((key) => {
          if (item.hasOwnProperty(key)) {
            this.$set(this.bindedDirectivesData, ind, {
              ...this.bindedDirectivesData[ind],
              [key]: params[key],
            })
          }
        })
        // return item;
      })
    },
    batchSetFunc(key) {
      this.bindedDirectivesData = this.bindedDirectivesData.map((item) => {
        item[key] = this.batchSettingObj[key]
        return item
      })
    },
    switchModalVisible(bol) {
      this.isShowModal = bol
      if (bol) {
        this.initView()
        this.getCommonTreeDatas()
        this.getCommonInstList(true)
      }
    },
    handleSizeChange(v) {
      this.getDirectives({ page_size: v })
    },
    handleCurrentPageChange(v) {
      this.getDirectives({ page_num: v })
    },
    handleCommonSizeChange(v) {
      this.commonPageInfo.page_size = v
      this.getCommonInstList(true)
    },
    handleCommonCurrentPageChange(v) {
      this.commonPageInfo.page_num = v
      this.getCommonInstList()
    },
    getDirectiveTypes() {
      this.$request
        .queryDirectiveTypesList({ page_size: 9999, page_num: 1 })
        .then((res) => {
          if (res.status != 'success') {
            this.$message.error(res.msg)
            return
          }
          this.directiveTypes = res?.data?.result || []
        })
    },
    removeDirectives() {
      this.bindedDirectivesData = _.differenceBy(
        this.bindedDirectivesData,
        this.checkedBindedD,
        'temId'
      )
    },
    addDirectives() {
      if (this.checkedD.length == 0) {
        this.$message.error('请先勾选要操作的数据')
        return
      }
      console.log(this.checkedD)
      const data = this.checkedD.map((i) => {
        i.temId = Math.random() * new Date().valueOf()
        return i
      })
      const newA = data.reduce((re, item) => {
        re.push({
          ...item,
          // order: "0",
          delay: '0',
          counts: '1',
          ip: '0',
          port: '0',
          mustValid: '',
        })
        return re
      }, [])
      this.bindedDirectivesData = [...this.bindedDirectivesData, ...newA]
    },
    getDirectives({ page_size, page_num }) {
      const { directiveName, directiveType } =
        this.searchDirectivesConditions || {}
        let defaultPageNum, defaultPageSize;
      if (this.$refs.pagination) {
        const { pageNum, pageSize } = this.$refs.pagination.getPageInfo();
        defaultPageNum = pageNum;
        defaultPageSize = pageSize;
      } else {
        defaultPageNum = 1;
        defaultPageSize = 10;
      }
      this.$request
        .queryDirectivesList({
          page_num: page_num || defaultPageNum,
          page_size: page_size || defaultPageSize,
          instruction_name: directiveName,
          type_id: directiveType,
        })
        .then((res) => {
          if (res.status != 'success') {
            this.$message.error(res.msg)
            return
          }
          this.directivesDataInfo = res?.data || {}
        })
    },
    initDirectivesData() {
      this.getDirectives({})
    },
    searchDirectives() {
      const { directiveName, directiveType } = this.searchDirectivesConditions
      if (this.$refs.pagination) {
        this.$refs.pagination.setPageSize(10)
      }
      // 记录搜索条件中的--类型
      sessionStorage.setItem(
        'modalDirectiveType',
        this.searchDirectivesConditions.directiveType
      )
      this.getDirectives({
        instruction_name: directiveName,
        type_id: directiveType,
      })
    },
    handleCheckedDChange(s, type) {
      //type  1: 左侧 2：右侧
      if (type == 1) {
        this.checkedD = s
      } else {
        this.checkedBindedD = s
      }
    },
    onTableCellChange(v, row, valueKey) {
      const i = this.bindedDirectivesData.findIndex((i) => i.temId == row.temId)
      this.bindedDirectivesData[i][valueKey] = v
    }
  }
}
</script>

<style lang="scss">
.instruction_name_popover {
  white-space: break-spaces;
  line-height: 25px;
}
  .searchForm {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      flex-shrink: 0;
      display: inline-flex;
      flex: 1;
      .el-form-item__label {
        flex-shrink: 0;
      }
      &:last-child {
        flex: 0;
        margin-right: 0px;
      }
    }
    .el-form-item__content {
      position: initial;
    }
    // .searchBtn {
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    // }
  }
  .allDirectives {
    position: relative;
    width: 40% !important;
    flex: none;
    // .allDirectivesTable {
    //   height: 338px;
    //   overflow-y: auto;
    // }
    .el-pagination {
      margin: 24px 0px 0px;
    }
  }
  .selectedDirectives {
    flex: 2;
    overflow: auto;
    .el-input-number--small {
      width: 100%;
      .el-input__inner {
        text-align: left;
      }
    }
    .batchSet {
      margin-bottom: 16px;
      display: flex;
      .el-button {
        line-height: 16px;
      }
      .box {
        flex: 1;
        position: relative;
        padding-left: 48px;
        label {
          position: absolute;
          left: 0px;
          line-height: 32px;
          width: 41px;
          text-align: right;
        }
      }
      .validateChk {
        line-height: 32px;
        margin-left: 10px;
      }
      .batchText {
        color: #000;
        font-size: 20px;
        line-height: 32px;
      }
      // .setBtn {
      //   margin-right: 12px;
      // }
      .input {
        .el-input-group__append {
          background: #409eff;
          color: #fff;
          border-color: #409eff;
          &:hover {
            background: #66b1ff;
            border-color: #66b1ff;
            color: #fff;
          }
        }
      }
    }
  }
  .switches {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90px;
    & div {
      margin-bottom: 12px;
    }
  }
</style>
<style lang="scss" scoped>
.directives-container {
  display: flex;
  flex-direction: row;
  height: calc(100% - 32px);
}
</style>
